import "./index.scss";

const CarBanner = () => {
  return (
    <div className="car_banner">
      <p>SUPER FIVE TRAVEL AND TOURISM L.L.C</p>
    </div>
  );
};

export default CarBanner;
