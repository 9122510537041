import Rectangle1 from "assets/img/car/t1.jpeg";
import Rectangle2 from "assets/img/car/t2.jpeg";
import Rectangle3 from "assets/img/car/t3.jpeg";
import "./index.scss";

const Services = () => {
  return (
    <div className="service_box">
      <div className="title">About Us</div>
      <div className="desc">
        <p>
          Our Mission is to provide outstanding service to each of our clientele
          and partners.
        </p>
        <p>
          We perform ourselves to the repeated improvement of our processes and
          fulfillment of requirements during the implementation of our business.
          To be leaders and tendency setters in the travel industry, in all
          avenues of business that we are linked with and utilizing all our
          Labours and elegant services not only during our visitors visit but
          also accomplish long term associations forever to be recognized as a
          leading supplier of quality travel and tourism-related services
          international.
        </p>
      </div>
      <div className="about_us">
        <img src={Rectangle2} alt=""></img>
        <img src={Rectangle1} alt=""></img>
        <img src={Rectangle3} alt=""></img>
      </div>
    </div>
  );
};
export default Services;
