import React from "react";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import card1 from "assets/img/car/card1.jpeg";
import card2 from "assets/img/car/card2.png";
import card3 from "assets/img/car/card3.jpeg";
import card4 from "assets/img/car/card4.jpeg";
import "./index.scss";

const CarModel = () => {
  const cards = [
    {
      image: card1,
      title: "Custom Trip",
      price: 1,
      duration: "Custom",
      schedule: "15 Nov, 16 Nov, 17 Nov, and...",
      location: null,
    },
    {
      image: card4,
      title: "Dubai City Tour",
      price: 115,
      duration: "4 Hours",
      schedule: "Pick up Time: 09:00 AM, ...",
      location: "Dubai",
    },
    {
      image: card3,
      title: "Desert Safari with Dinner and Live Shows",
      price: 125,
      duration: "6 Hours",
      schedule: "Daily, 03:00 PM to 09:00 PM",
      location: null,
    },
    {
      image: card2,
      title: "Desert Safari with Dinner and Live Shows (Private Car)",
      price: 725,
      duration: "6 Hours",
      schedule: "Daily, 03:30 PM to 09:30 PM",
      location: null,
    },
  ];

  const handleButtonClick = () => {
    window.open(
      "https://api.whatsapp.com/message/EZPPLY3WADKOA1?autoload=1&app_absent=0",
      "_blank" //
    );
  };

  const Card = ({ image, title, price, duration, schedule }) => (
    <div className="card">
      <div className="card-image">
        <img src={image} alt={title} />
        <div className="card-price">AED {price}</div>
      </div>
      <div className="card-content">
        <div className="card-title">{title}</div>
        <div className="card-details">
          <ClockCircleOutlined className="icon" />
          <span className="text">{duration}</span>
        </div>
        <div className="card-details">
          <CalendarOutlined className="icon" />
          <span className="text">{schedule}</span>
        </div>
      </div>
      <div className="card-footer">
        <button onClick={handleButtonClick}>Order Now</button>
      </div>
    </div>
  );

  return (
    <div className="card-model">
      {cards.map((card, index) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};

export default CarModel;
